import React from "react";
import donate from "../../images/donate.png";
import sectionIcon from "../../images/section-icon.png";
import { Link } from "gatsby";
const WhyShouldIDonate = () => {
    return (
        <section className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="about-semi-img">
                            <img src={donate} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="about-heading">
                            <div className="section-heading">
                               
                                <h2 className="section__title">Why should I Donate to JCI India Foundation ?</h2>

                                <p className="section__desc">
                                    Donating to JCI India Foundation, gives you the opportunity to activity support the genuine needy students of India in their education  and to support the programs of JCI India. While doing so, repay the organization for all that it has given to you. Participation in the Foundation brings deep personal satisfaction that comes from the knowledge that you have done your part to make the world a better place.

                                </p>
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                <p className="section__desc">
                                    “Giving is not just about making a donation, It is about making a difference in others life”, this is what JCI India Foundation is doing since decades.
                                </p>
                                <p className="section__desc">
                                    The funds donated to the foundation, are invested in financial help through scholarship to school children who are economically deprived. Donations to the Foundation remain in an interest-bearing account and the interest generated on such gifts is allotted for JCI India activities on need basis.  JCI India foundation has grown substantially. It was established as a trust (Indian Jaycees Charitable Trust) on 20th October 1973 and after a long journey, it has been converted into Section 8 company in 2024, for bigger benefits to society.  The new office for JCI India Foundation is inaugurated on 8th floor of Windfall building, Andheri, Mumbai with Pooja in January 2024
                                </p>
                                <p className="section__desc">
                                    Contributions to JCI India Foundation are exempted u/s  80G & 12A  of the income tax act, so your donation gives you savings on tax.

                                </p>
                                <p className="section__desc">
                                    In early days, the funds donated to the Foundation were invested in training of Local Presidents i.e. for Presidential Academy. Later on, looking into more substantial perspective, the fund got utilized for financial help to school children of rural India who are genuinely economically deprived. To find the talented children and help them pursue their studies became the motto of JCI India Foundation. JCI India Scholarship program introduced from 2005 and till then apprx 20,000 needy children got benefitted through JCI India Foundation scholarship till now.
                                </p>
                                <p className="section__desc">
                                    The scholarship program was known as HGF scholarship in those days. HGF stands for Henry Giessenbier Fellow. Henry Giessenbier is the founder of JCI, hence the scholarship was named in his memory. Students from poor income group, differently abled, talented girl students, single parent students are selected for scholarship.


                                </p>
                                <p className="section__desc">
                                    The Scholarship program was started initially by helping 500 children every year by giving 500/- to each. Gradually the number of students increased to 1000 every year (donating Rs. 2000/- to each) and now every year approx. 2000-3000 students get the scholarship with Rs. 3000/- to each.

                                </p>
                                <p className="section__desc">
                                    The popularity of the scholarship program reached pan India and in year 2024, highest number of scholarship applications received ever. This year, due to timely and regular promotion to all level, 6536 applications received from all over India.

                                </p>
                                <p className="section__desc">
                                    New scholarship program “Empower Her” introduced this year, to help needy talented girl children from 9th to 12th standard. Each student will get Rs. 1000/- every month for 10 months every year for 1/2/3/4 years based on her result and standard of selection. These students must be from Government school/government aided school only.


                                </p>
                                <p className="section__desc">
                                    We are having approx. 300 major donors. From past National Presidents to current Local Organization President to grass root member, all have come forwarded spontaneously to donate and became highly respectful Major Donors of JCI India Foundation.

                                </p>
                                <p className="section__desc"><Link to="/donate" className="theme-btn">
                                    donate now
                                </Link></p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShouldIDonate;
